exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-account-address-book-index-tsx": () => import("./../../../src/pages/app/account/address-book/index.tsx" /* webpackChunkName: "component---src-pages-app-account-address-book-index-tsx" */),
  "component---src-pages-app-account-change-password-index-tsx": () => import("./../../../src/pages/app/account/change-password/index.tsx" /* webpackChunkName: "component---src-pages-app-account-change-password-index-tsx" */),
  "component---src-pages-app-account-change-pin-index-tsx": () => import("./../../../src/pages/app/account/change-pin/index.tsx" /* webpackChunkName: "component---src-pages-app-account-change-pin-index-tsx" */),
  "component---src-pages-app-account-faqs-index-tsx": () => import("./../../../src/pages/app/account/faqs/index.tsx" /* webpackChunkName: "component---src-pages-app-account-faqs-index-tsx" */),
  "component---src-pages-app-account-gift-cards-[id]-tsx": () => import("./../../../src/pages/app/account/gift-cards/[id].tsx" /* webpackChunkName: "component---src-pages-app-account-gift-cards-[id]-tsx" */),
  "component---src-pages-app-account-gift-cards-index-tsx": () => import("./../../../src/pages/app/account/gift-cards/index.tsx" /* webpackChunkName: "component---src-pages-app-account-gift-cards-index-tsx" */),
  "component---src-pages-app-account-index-tsx": () => import("./../../../src/pages/app/account/index.tsx" /* webpackChunkName: "component---src-pages-app-account-index-tsx" */),
  "component---src-pages-app-account-my-orders-index-tsx": () => import("./../../../src/pages/app/account/my-orders/index.tsx" /* webpackChunkName: "component---src-pages-app-account-my-orders-index-tsx" */),
  "component---src-pages-app-account-my-profile-index-tsx": () => import("./../../../src/pages/app/account/my-profile/index.tsx" /* webpackChunkName: "component---src-pages-app-account-my-profile-index-tsx" */),
  "component---src-pages-app-account-security-index-tsx": () => import("./../../../src/pages/app/account/security/index.tsx" /* webpackChunkName: "component---src-pages-app-account-security-index-tsx" */),
  "component---src-pages-app-buy-airtime-index-tsx": () => import("./../../../src/pages/app/buy-airtime/index.tsx" /* webpackChunkName: "component---src-pages-app-buy-airtime-index-tsx" */),
  "component---src-pages-app-buy-data-index-tsx": () => import("./../../../src/pages/app/buy-data/index.tsx" /* webpackChunkName: "component---src-pages-app-buy-data-index-tsx" */),
  "component---src-pages-app-cards-[id]-tsx": () => import("./../../../src/pages/app/cards/[id].tsx" /* webpackChunkName: "component---src-pages-app-cards-[id]-tsx" */),
  "component---src-pages-app-cards-create-card-index-tsx": () => import("./../../../src/pages/app/cards/create-card/index.tsx" /* webpackChunkName: "component---src-pages-app-cards-create-card-index-tsx" */),
  "component---src-pages-app-cards-index-tsx": () => import("./../../../src/pages/app/cards/index.tsx" /* webpackChunkName: "component---src-pages-app-cards-index-tsx" */),
  "component---src-pages-app-categories-[name]-tsx": () => import("./../../../src/pages/app/categories/[name].tsx" /* webpackChunkName: "component---src-pages-app-categories-[name]-tsx" */),
  "component---src-pages-app-categories-index-tsx": () => import("./../../../src/pages/app/categories/index.tsx" /* webpackChunkName: "component---src-pages-app-categories-index-tsx" */),
  "component---src-pages-app-dashboard-index-tsx": () => import("./../../../src/pages/app/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-app-dashboard-index-tsx" */),
  "component---src-pages-app-forgot-password-index-tsx": () => import("./../../../src/pages/app/forgot-password/index.tsx" /* webpackChunkName: "component---src-pages-app-forgot-password-index-tsx" */),
  "component---src-pages-app-index-tsx": () => import("./../../../src/pages/app/index.tsx" /* webpackChunkName: "component---src-pages-app-index-tsx" */),
  "component---src-pages-app-list-[name]-tsx": () => import("./../../../src/pages/app/list/[name].tsx" /* webpackChunkName: "component---src-pages-app-list-[name]-tsx" */),
  "component---src-pages-app-list-create-list-index-tsx": () => import("./../../../src/pages/app/list/create-list/index.tsx" /* webpackChunkName: "component---src-pages-app-list-create-list-index-tsx" */),
  "component---src-pages-app-list-index-tsx": () => import("./../../../src/pages/app/list/index.tsx" /* webpackChunkName: "component---src-pages-app-list-index-tsx" */),
  "component---src-pages-app-login-index-tsx": () => import("./../../../src/pages/app/login/index.tsx" /* webpackChunkName: "component---src-pages-app-login-index-tsx" */),
  "component---src-pages-app-notifications-index-tsx": () => import("./../../../src/pages/app/notifications/index.tsx" /* webpackChunkName: "component---src-pages-app-notifications-index-tsx" */),
  "component---src-pages-app-packages-index-tsx": () => import("./../../../src/pages/app/packages/index.tsx" /* webpackChunkName: "component---src-pages-app-packages-index-tsx" */),
  "component---src-pages-app-reset-password-index-tsx": () => import("./../../../src/pages/app/reset-password/index.tsx" /* webpackChunkName: "component---src-pages-app-reset-password-index-tsx" */),
  "component---src-pages-app-schedule-airtime-index-tsx": () => import("./../../../src/pages/app/schedule-airtime/index.tsx" /* webpackChunkName: "component---src-pages-app-schedule-airtime-index-tsx" */),
  "component---src-pages-app-schedule-data-index-tsx": () => import("./../../../src/pages/app/schedule-data/index.tsx" /* webpackChunkName: "component---src-pages-app-schedule-data-index-tsx" */),
  "component---src-pages-app-services-[id]-tsx": () => import("./../../../src/pages/app/services/[id].tsx" /* webpackChunkName: "component---src-pages-app-services-[id]-tsx" */),
  "component---src-pages-app-services-create-service-index-tsx": () => import("./../../../src/pages/app/services/create-service/index.tsx" /* webpackChunkName: "component---src-pages-app-services-create-service-index-tsx" */),
  "component---src-pages-app-services-edit-service-index-tsx": () => import("./../../../src/pages/app/services/edit-service/index.tsx" /* webpackChunkName: "component---src-pages-app-services-edit-service-index-tsx" */),
  "component---src-pages-app-services-index-tsx": () => import("./../../../src/pages/app/services/index.tsx" /* webpackChunkName: "component---src-pages-app-services-index-tsx" */),
  "component---src-pages-app-sign-up-index-tsx": () => import("./../../../src/pages/app/sign-up/index.tsx" /* webpackChunkName: "component---src-pages-app-sign-up-index-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-order-[id]-tsx": () => import("./../../../src/pages/order/[id].tsx" /* webpackChunkName: "component---src-pages-order-[id]-tsx" */),
  "component---src-pages-order-index-tsx": () => import("./../../../src/pages/order/index.tsx" /* webpackChunkName: "component---src-pages-order-index-tsx" */),
  "component---src-pages-packages-tsx": () => import("./../../../src/pages/packages.tsx" /* webpackChunkName: "component---src-pages-packages-tsx" */)
}

